@import 'variables.scss';

.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: $white;
  top: 0;
  left: 0;

  height: 100%;
  width: 100%;
  z-index: 150;

  position: absolute;

  opacity: 1;
  transition: opacity 0.1s ease;

  .logo {
    width: 80%;
    max-width: 300px;
    margin-bottom: 50px;
    fill: #fff;

    @media (min-width: 768px) {
      width: initial;
    }
  }

  .mobileMenu {
    display: flex;
    flex-direction: column;
    font-size: 30px;

    hr {
      margin: 15px 0;
    }
    button {
      margin: auto;
      display: block;
    }

    .link {
      align-self: flex-start;
      margin: 5px auto;
    }
  }
}

.dark.wrapper {
  background: $nearBlack;
}

.close {
  position: absolute;
  top: 28px;
  left: 12px;

  cursor: pointer;
}

.hidden {
  opacity: 0;
  transition: opacity 0.3s ease;
  pointer-events: none;
}