.activityList {
  font-size: 18px;
}

:global(.MuiDataGrid-columnsContaine)r {
  background: rgba(0, 0, 0, 0.2);
}

.dark {
  :global(.MuiDataGrid-columnsContaine)r {
    background: rgba(255, 255, 255, 0.2);
  }
}