// TODO: move to base theme
@import 'variables.scss';

#root {
  min-height: 100vh;
}

/* Fonts */

@font-face {
	font-family: "Nike TG";
	font-style: normal;
	font-weight: 400;
	src: url(//web.nike.com/ncss/0.13/admin/fonts/257c802f-349c-4b4d-aefa-546d5de15ec6.eot);
	src: url(//web.nike.com/ncss/0.13/admin/fonts/257c802f-349c-4b4d-aefa-546d5de15ec6.eot?#iefix) format('embedded-opentype'), url(//web.nike.com/ncss/0.13/admin/fonts/257c802f-349c-4b4d-aefa-546d5de15ec6.woff) format('woff'), url(//web.nike.com/ncss/0.13/admin/fonts/257c802f-349c-4b4d-aefa-546d5de15ec6.ttf) format('truetype'), url(//web.nike.com/ncss/0.13/admin/fonts/257c802f-349c-4b4d-aefa-546d5de15ec6.svg#257c802f-349c-4b4d-aefa-546d5de15ec6) format('svg')
}

a {
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
}

.light {
  svg {
    fill: $black;
    // stroke: $black;
  } 
}

.dark {
  svg {
    fill: $white;
    // stroke: $white;
  }  
}
