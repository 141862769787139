.link {
  cursor: pointer;
  
  &:hover {
    text-decoration: underline;
  }
}
.notALink {
  cursor: default;

  &:hover {
    text-decoration: none;
  }
}

// fixme
// .externalLink {
//   display: flex;
//   align-items: center;

//   &.text::before {
//     content: "";
//     display: inline-block;
//     background: url("./external.svg") no-repeat;
//     width: 15px;
//     height: 15px;
//     margin-right: 3px;
//   }

// }
