.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  max-width: 100%;
}

.header {
  color: #ee3030;
}

.message {
  max-width: 100%;
  overflow: scroll;
}

.stack {
  margin-top: 25px;
  max-width: 600px;
  overflow: scroll;
}

.buttons {
  margin-top: 25px;

  button {
    margin: 5px;
  }
}
