@import 'variables';

.container {
    margin-top: 20px;
    margin-bottom: 20px;
}


.project {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    transition: 0.25s;
    border-radius: 5px;
    height: 150px;
    width: 100%;
    text-align: center;
    padding: 10px;

    h3 {
      font-family: monospace;
      font-size: 18px;
      font-weight: bold;
    }

    &:hover {
        background-color: rgba(150, 150, 150, 0.5);
    }
}


.textColor {
    color: $black;
}

.container.dark {
  .textColor {
    color: $white;
  }
}



.video {
  margin: 0 auto;
  display: block;
  width: 200px;
  border-radius: 5px;
  padding: 10px;
  background: #2481d1;
}

.logo {
  max-height: 80%;
  margin: 10px;
  max-width: 70%
}
