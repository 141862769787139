.dataGridPagination {
  
}

.nextPrev {
  min-width: 20px;
  padding: 5px 15px;

  &:disabled {
    cursor: no-drop;
    pointer-events: initial;
  }
  &:first-of-type {
    margin-right: 5px;
  }
  &:last-of-type {
    margin-left: 5px;
  }
}

.page {
  min-width: 20px;
  padding: 5px 15px;

  &.active {
    background: rgba(100, 100, 100, 0.1);
    color: rgba(0, 0, 0, 0.87);
  }
}