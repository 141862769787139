html, body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  min-height: 100vh;
}

a, a:visited, a:hover, a:active {
  color: inherit;
}

p {
  word-break: break-all;
}

.react-toast-notifications__toast__content {
  display: flex;
  align-items: center;
}
