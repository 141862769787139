.fileDrop {
  position: relative;
  border-radius: 10px;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(75, 75, 75, 0.3);
  color: black;
  transition: opacity 0.2s ease, border 0.2s ease;
  // border: 4px dash rgba(255, 255, 255, 0);
  border: 4px dashed rgba(0, 0, 0, 0.5);
  pointer-events: none;

  &.isInvalid {
    background: rgba(120, 0, 0, 0.7);
    cursor: no-drop;
  }
}


.isDragging {
  .overlay {
    background: rgba(75, 75, 75, 0.6);
    
    &.isInvalid {
      background: rgba(120, 0, 0, 0.7);
      cursor: no-drop;
    }
  }
}