@import 'variables';

// .visualizeView {
  
// }

.fileDrop {
  width: 100%;
  min-width: 100%;
  font-size: 60px;
}