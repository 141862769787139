.code {
  margin: 0;
  padding: 15px;
  color: rgb(51, 51, 51);
  overflow: scroll;
  display: inline-block;
  border-radius: 3px;
  font-size: 85%;
  cursor: text;
  max-width: 100%;
}

.light {
  background: rgba(27, 31, 35, .05);
  color: rgb(51, 51, 51);
}

.dark {
  background: rgba(255, 255, 255, .05);
  color: white;
}

.inline {
  display: inline;
  padding: .2em .4em;
  white-space: normal;
  line-height: 2em;
}
