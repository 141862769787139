@import 'variables.scss';

.wrapper {
    // background-color: #000;
    height: 100vh;
    width: 100vw;

    > div {
        display: grid;
        grid-template-columns: 1fr;
        justify-content: center;
        grid-gap: 50px;

        img {
            height: 100px;
            width: auto;
        }
    }
}

.logo {
  height: 100px;
}

.dark.wrapper {
  background-color: $nearBlack;
  color: $white;
}
